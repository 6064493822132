<template>
  <div class="unfinished screen center">
    <h1>In progress...</h1>
    <br>
    <br>
    <div class="stored-data">
      <p>Stored data so far...</p>
      <br>
      <br>
      <h4>TaskInfo:</h4>
      <vue-json-pretty :data="getTaskInfo"/>
      <br>
      <br>
      <h4>QuizInfo:</h4>
      <vue-json-pretty :data="getQuizzes"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'unfinished',
  components: {
    VueJsonPretty
  },
  computed: {
    ...mapGetters(['getTaskInfo', 'getQuizzes'])
  }
}
</script>

<style lang="scss" scoped>
  .unfinished {
    margin: 5rem auto;
  }
</style>